.owl-carousel {
	.owl-item {
	  float: left;
	}
  
	.owl-wrapper-outer {
	  overflow-inline: -moz-hidden-unscrollable;
	  overflow-inline: hidden-unscrollable;
	  overflow-x: clip;
	  position: relative;
	  width: 100%;
	  direction: ltr;
	}
  
	.owl-wrapper, .owl-item {
	  backface-visibility: hidden;
	  -webkit-backface-visibility: hidden;
	  -moz-backface-visibility: hidden;
	  -ms-backface-visibility: hidden;
	  -webkit-transform: translate3d(0, 0, 0);
	  -moz-transform: translate3d(0, 0, 0);
	  -ms-transform: translate3d(0, 0, 0);
	}
  
	.owl-wrapper {
	  display: none;
	  position: relative;
	  -webkit-transform: translate3d(0px, 0px, 0px);
	}
  }